<template>
  <div class="resultContainer"  v-if="$route.path === '/RedBag/Withdraw/Result'">
    <div v-wechat-title="$route.meta.title"></div>
      <img src="" alt="" ref="img">
      <div class="result">{{result}}</div>
      <div class="resultDesc">{{resultDesc}}</div>
      <div class="know" @click="close">我知道了</div>
  </div>
</template>
<script>
export default{
    data(){
        return{
            userId: "",
            token: "",
            result:'',
            resultDesc:'',
            src:'',
            type:'',
            imgSuccess:require('../img/分组@2x.png'),
            imgFail:require('../img/红色-叉 2@2x.png')
        }
    },
    mounted(){
        //获取userId和token
        this.userId = this.getRequest().userId ? this.getRequest().userId : "";
        this.token = this.getRequest().token ? this.getRequest().token : "";
        this.type = this.getRequest().type ? this.getRequest().type : "";
        if(this.type == 1){
            this.result = '提取成功'
            this.resultDesc = '提取申请已提交，预计2小时内到账'
            this.$refs.img.src = this.imgSuccess
        }else{
            this.result = '提取失败'
            this.resultDesc = '提取申请提交失败，请重新提交'
            this.$refs.img.src = this.imgFail
        }
    },
    methods:{
        close(){
            this.$router.replace(`/RedBag?userId=${this.userId}&token=${this.token}`)
        },
        getRequest: () => {
            var url = window.location.search; //获取url中"?"符后的字串
            var theRequest = new Object();
            if (url.indexOf("?") != -1) {
                var str = url.substr(1);
                var strs = str.split("&");
                for (var i = 0; i < strs.length; i++) {
                    theRequest[strs[i].split("=")[0]] = decodeURI(
                        strs[i].split("=")[1]
                    );
                }
            }

            return theRequest;
        },
        
    }
}
</script>
<style scoped>
.resultContainer{
    background: #F2F2F2;
    height: 100vh;
    padding-top: 80px;
    box-sizing: border-box;
}
img{
    display: block;
    width: 60px;
    height: 60px;
    margin: 0 auto 20px;
}
.result{
    width: 72px;
    height: 25px;
    font-size: 18px;
    font-family: Helvetica;
    color: #333333;
    margin: 0 auto;
}
.resultDesc{
    width: 219px;
    height: 20px;
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #4A4A4A;
    margin: 10px auto 61px;
    text-align: center;
}
.know{
    width: 335px;
    height: 46px;
    background: #E86258;
    border-radius: 4px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 46px;
    text-align: center;
    margin: 0 auto;
}
</style>